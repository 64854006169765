import React, { forwardRef, InputHTMLAttributes, Ref } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputTitle?: string;
  iconSource?: string;
  errorMsg?: string;
}

export default forwardRef(function InputWithBottomBorder(
  { inputTitle, iconSource, errorMsg, ...props }: Props,
  ref: Ref<HTMLInputElement>
) {
  /* note: there are 2 inputs. one input with title and one with just input */
  if (inputTitle)
    return (
      <div className='flex flex-col gap-2'>
        {/* Header */}
        <div className='flex flex-row gap-2'>
          {iconSource && iconSource != '' ? (
            <>
              <img alt='icon' src={iconSource} className='h-5' />
              <h1>{inputTitle}</h1>
            </>
          ) : (
            <>
              <h1 className='ml-2'>{inputTitle}</h1>
            </>
          )}
        </div>
        {/* Input */}
        <div className='flex items-center'>
          <input
            className='
              w-full
              px-2
              py-2
              text-base
              rounded-none
              bg-transparent
              border-b-2
              border-[slookuLightGreyBackground]
              focus:outline-none
              focus:border-blue-400'
            {...props}
            ref={ref}
          />
        </div>
      </div>
    );
  return (
    <div className='flex flex-col gap-2'>
      <input
        className='
          w-full
          px-2
          py-2
          text-base
          rounded-none
          border-b-2 border-[slookuLightGreyBackground]
          focus:outline-none focus:border-blue-400'
        {...props}
        ref={ref}
      />
      {errorMsg && <p className='text-base text-red-500'>{errorMsg}</p>}
    </div>
  );
});
