// when a user of type contractor logins it will redirect to this page
export const contractorLandingPath = '../contractor/templates';

/* firebase documents, collections and sub collections */
export const contractorDocumentsCollection = 'contractor_documents';
export const contractorsCollection = 'contractors';
export const contractsSubCollection = 'contracts';
/* END - firebase documents, collections and sub collections */

/* ************************************** */
/* CLIENT SIDE AND SERVER SIDE VALIDATION */
/* ************************************** */
export function sanitizeEmail(email: string): string {
  return email.trim().toLowerCase();
}

export const getEmailRegexPattern = () => {
  return new RegExp(EmailRegexValidation.PATTERN);
};

export enum PassPropertyValidation {
  MIN_LENGTH = 6,
  MAX_LENGTH = 50,
}

export enum EmailPropertyValidation {
  MAX_LENGTH = 50,
}

export enum EmailRegexValidation {
  // actual value: ^[^\s@]+@[^\s@]+\.[^\s@]+$
  PATTERN = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$', // Store regex pattern as a string
}

export enum AuthValidationMessages {
  REQUIRED = 'This field is required.',
  INCORRECT_FORMAT = 'Email is in wrong format.',
  MAX_LENGTH_OF_50 = 'Must not be greater than 50 characters.',
  PASSWORD_REQUIRED = 'Password is required',
  PASSWORD_MIN_LENGTH = 'Password must be at least 6 characters.',
  PASSWORD_MAX_LENGTH = 'Password must not be greater than 50 characters.',
  PASSWORDS_DO_NOT_MATCH = 'Your passwords do not match',
}
/* END - CLIENT SIDE AND SERVER SIDE VALIDATION */
