import React from 'react';

export function SubscriptionManagement() {
  const userDoesNotHaveSubscription = true;
  const userHasCancelledSubscription = false;

  function PlanCard() {
    return (
      <div className='flex flex-col bg-[#F5F6F8] rounded-2xl gap-6 p-4'>
        {/* plan name */}
        <div className='flex flex-col sm2:flex-row justify-between'>
          {/* plan type */}
          <span className='text-5xl font-extralight text-blueOne'>Basic</span>
          {/* plan price */}
          <div className='flex flex-row items-end gap-2'>
            <span className='text-black text-4xl font-light '>$0</span>
            <span className='text-[#6D727F] text-2xl font-light '>/ month</span>
          </div>
        </div>
        {/* plan description */}
        <span className='text-[#6D727F] text-xl xs:text-2xl font-light'>
          only 3 contracts per months
        </span>
        {/* change plan button */}
        <div className='flex flex-col items-center'>
          <div>
            <button className='bg-blueOne text-white rounded-3xl px-6 py-2'>
              Change Plan
            </button>
          </div>
        </div>
      </div>
    );
  }

  function CancelSubscriptionLink() {
    return (
      <div className='flex flex-col text-center'>
        <div>
          <a className='text-xl text-blueOne hover:underline hover:cursor-pointer'>
            Cancel Subscription
          </a>
        </div>
      </div>
    );
  }

  function BillingInformation() {
    return (
      <div className='flex flex-col gap-4'>
        {/* sub header */}
        <h3 className='text-2xl font-normal'>Billing Information</h3>
        {/* billing information */}
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-0'>
          {/* email */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Card Number</div>
            <div className='text-black text-xl'>account@email.com</div>
          </div>
          {/* bill date */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Bill Date</div>
            <div className='text-black text-xl'>11/11/2031</div>
          </div>
        </div>
      </div>
    );
  }

  function PaymentInformation() {
    return (
      <div className='flex flex-col gap-4'>
        {/* sub header */}
        <h3 className='text-2xl font-normal'>Payment Method</h3>
        {/* card information */}
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-0'>
          {/* card number */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Card Number</div>
            <div className='flex flex-row gap-1'>
              <div className='text-black text-xl'>**** **** ****</div>
              <div className='text-black text-xl'>1234</div>
            </div>
          </div>
          {/* card exp date */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>
              Expiration Date
            </div>
            <div className='text-black text-xl'>11/11/2031</div>
          </div>
        </div>
      </div>
    );
  }

  function ChangePaymentInfoLink() {
    return (
      <div className='flex flex-col text-center'>
        <div>
          <a className='text-xl text-blueOne hover:underline hover:cursor-pointer'>
            Change Payment Information
          </a>
        </div>
      </div>
    );
  }

  function CancelledPlanInformation() {
    return (
      <div className='flex flex-col gap-4'>
        {/* sub header */}
        <h3 className='text-2xl font-normal'>Cancelled Plan</h3>
        {/* subscription information */}
        <div className='grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-0'>
          {/* type */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>
              Subscription
            </div>
            <div className='text-black text-xl'>Ultimate</div>
          </div>
          {/* status */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Status</div>
            <div className='text-black text-xl'>Cancelled</div>
          </div>
          {/* end date */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Access Ends</div>
            <div className='text-black text-xl'>11/11/2031</div>
          </div>
        </div>
      </div>
    );
  }

  // user has never enrolled in a subscription
  if (userDoesNotHaveSubscription) {
    return (
      <div className='flex flex-col gap-8'>
        <h1 className='text-2xl font-bold'>Subscription Management</h1>
        <h3 className='text-2xl font-normal'>Current Plan</h3>
        <PlanCard />
      </div>
    );
  }

  // user has cancelled a subscription
  if (userHasCancelledSubscription) {
    return (
      <div className='flex flex-col gap-8'>
        <h1 className='text-2xl font-bold'>Subscription Management</h1>
        <h3 className='text-2xl font-normal'>Current Plan</h3>
        <PlanCard />
        <CancelledPlanInformation />
      </div>
    );
  }

  // user has active subscription
  return (
    <div className='flex flex-col gap-8'>
      <h1 className='text-2xl font-bold'>Subscription Management</h1>
      <h3 className='text-2xl font-normal'>Current Plan</h3>
      <PlanCard />
      <CancelSubscriptionLink />
      <BillingInformation />
      <PaymentInformation />
      <ChangePaymentInfoLink />
      <CancelledPlanInformation />
    </div>
  );
}
