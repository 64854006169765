import React from 'react';
import { useForm } from 'react-hook-form';
import { SignInDataForm } from '../../../../types';
import {
  ButtonWithLoadingSpinner,
  RoundedInputWithValidation,
} from '../../../../components';
import { useAuthenticate } from '../../../../hooks';
import {
  AuthValidationMessages,
  EmailPropertyValidation,
  getEmailRegexPattern,
  sanitizeEmail,
} from '../../../../shared/constants';

type Props = {
  handleToggleClick: () => void;
};

export default function PasswordResetForm({
  handleToggleClick,
}: Props): JSX.Element {
  const {
    // resetPassword,
    successMsg: passwordResetSuccessMsg,
    error: passwordResetError,
    loading: passwordResetLoading,
  } = useAuthenticate();

  /* onTouched - validation will trigger on the first blur event. After that, it will trigger on every change event. */
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Omit<SignInDataForm, 'Password'>>({ mode: 'onTouched' });

  const handlePasswordReset = handleSubmit(async (data) => {
    // Sanitize the email
    data.email = sanitizeEmail(data.email);
    // await resetPassword(data);
    console.log('currently disabled ....');
  });

  return (
    <form
      className='bg-white bg-opacity-[.65] backdrop-blur-[2px] shadow-2xl rounded-2xl flex flex-col items-center w-[90%] sm2:w-[500px] lg:min-w-[550px] xl:min-w-[600px] py-20 mb-4'
      onSubmit={handlePasswordReset}
    >
      <div className='flex flex-col h-full w-[80%] m-4 gap-8'>
        {/* reset-password-form-title */}
        <div>
          <h1 className='text-4xl font-bold'>Reset Password</h1>
        </div>
        {/* Reset Password Input */}
        <div>
          {/* Email Address Input */}
          <RoundedInputWithValidation
            label='Email'
            placeholder='ex: user@email.com'
            error={errors.email?.message}
            {...register('email', {
              required: AuthValidationMessages.REQUIRED,
              pattern: {
                value: getEmailRegexPattern(),
                message:
                  AuthValidationMessages.INCORRECT_FORMAT /* ERROR MSG */,
              },
              maxLength: {
                value: EmailPropertyValidation.MAX_LENGTH,
                message:
                  AuthValidationMessages.MAX_LENGTH_OF_50 /* ERROR MSG */,
              },
            })}
          />
          {/* error from firebase while trying to sign in to account */}
          {passwordResetError && (
            <div className='flex flex-col pt-2'>
              <span className='text-sm text-red-500'>{passwordResetError}</span>
            </div>
          )}
          {/* success from firebase for password reset */}
          {passwordResetSuccessMsg && (
            <div className='flex flex-col pt-2'>
              <span className='text-sm text-green-500'>
                Please check your email to reset your password.
              </span>
            </div>
          )}
        </div>
        {/* reset password button and login form toggle */}
        <div className='flex flex-col items-center gap-16'>
          {/* reset password button */}
          <div className='flex flex-col w-full h-auto'>
            <ButtonWithLoadingSpinner
              loading={passwordResetLoading}
              disabled={passwordResetLoading}
              message='Send reset link'
            />
          </div>
          {/* login toggle */}
          <div className='flex flex-col'>
            <div
              className='flex flex-col gap-0 hover:cursor-pointer'
              onClick={handleToggleClick}
            >
              <p className='text-md xs:text-lg font-semibold'>
                Go back to login.
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
