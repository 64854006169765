import React from 'react';

type HeaderWithActionButtonsProps = {
  title: string;
  isEditing: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
};

export function HeaderWithActionButtons({
  title,
  isEditing,
  onEdit,
  onSave,
  onCancel,
}: HeaderWithActionButtonsProps): JSX.Element {
  return (
    <div className='flex flex-col-reverse sm2:flex-row sm2:justify-between'>
      {/* Title */}
      <div className='text-2xl font-bold'>
        <h1>{title}</h1>
      </div>
      {/* Edit Save Cancel Buttons */}
      <div className='flex flex-col items-end gap-4'>
        <div className='flex flex-row gap-4'>
          {isEditing ? (
            <>
              <button
                className='text-[#6D727F] text-2xl font-light hover:underline'
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className='text-blueOne text-2xl font-light hover:underline'
                onClick={onSave}
              >
                Save
              </button>
            </>
          ) : (
            <button
              className='text-blueOne text-2xl font-light hover:underline'
              onClick={onEdit}
            >
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
