import React, { useEffect, useState } from 'react';
import { ContractorInfo } from '../../../../../../types';
import {
  HeaderAndContentSkeleton,
  StatusMessageError,
} from '../../../../../../components';
import { GetContractorInformation } from '../../ContractorSettingsPageService';
import { CompanyInformationForm } from './CompanyInformationForm';
import { auth } from '../../../../../../firebase/config';

export function CompanyInformation(): JSX.Element {
  const [errorCI, setErrorCI] = useState('');
  const [loadingCI, setLoadingCI] = useState(true);
  const [contractorInfo, setContractorInfo] = useState<ContractorInfo | null>(
    null
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user !== null) {
        // fetch company information
        GetContractorInformation(user.uid)
          .then((contractorInfoObj) => {
            setContractorInfo(contractorInfoObj);
          })
          .catch(() => {
            setErrorCI('An error occurred while fetching company information');
          })
          .finally(() => {
            setLoadingCI(false);
          });
      }
    });

    return unsubscribe;
  }, []);

  /* Internal Components  */
  function CompanyInformationHeader() {
    return (
      <div className='flex flex-col'>
        {/* Title */}
        <div className='text-2xl font-bold'>
          <h1>Company Information</h1>
        </div>
      </div>
    );
  }
  /* END Internal Components  */

  function LoadingSkeletonContent() {
    return (
      <>
        <CompanyInformationHeader />
        <HeaderAndContentSkeleton />
      </>
    );
  }

  function ErrorContent() {
    return (
      <>
        <CompanyInformationHeader />
        <StatusMessageError message='An error occured. Please try again later.' />
      </>
    );
  }

  return (
    <div className='flex flex-col gap-4'>
      {/* loading skeleton */}
      {!contractorInfo && loadingCI && !errorCI && <LoadingSkeletonContent />}
      {/* error message */}
      {!contractorInfo && !loadingCI && errorCI && <ErrorContent />}
      {/* form */}
      {contractorInfo && !loadingCI && !errorCI && (
        <CompanyInformationForm contractorInfo={contractorInfo} />
      )}
    </div>
  );
}
